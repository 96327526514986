<template>
    <modal ref="modalFinalizarPedido" tamano="modal-xl" titulo="Finalizar Pedido" icon="bandera" :btns="btns" no-cancelar no-aceptar @accion="accion_modal">
        <div class="row mx-0">
            <div class="col-6">
                <p class="f-500 f-17 mb-2 text-general">Datos de la entrega</p>
            </div>
            <div class="col">
                <p class="f-500 f-17 mb-2 text-general">Datos de Envio</p>
            </div>
        </div>
        <div class="row mx-0 mb-4">
            <div class="col-4">
                <p class="text-general f-13 pl-3">
                    Instrucciones de la entrega
                </p>
                <p class="border br-7 p-2 f-11 bg-whitesmoke" v-html="formatearTextoHtml(pedido.instrucciones)" />
            </div>
            <div class="col-2">
                <div class="row">
                    <div class="px-0 mx-2">
                        <p class="text-general f-12">
                            Fecha de Entrega
                        </p>
                        <el-date-picker
                        v-model="model.entrega_fecha"
                        type="date"
                        size="small"
                        class="w-100"
                        placeholder="Fecha"
                        value-format="yyyy-MM-dd"
                        value="yyyy-MM-dd"
                        :clearable="false"
                        :picker-options="pickerOptions"
                        @change="set_franja"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="px-0 mx-2">
                        <p class="text-general f-12 ">
                            Hora de Entrega
                        </p>
                        <el-select v-model="model.entrega_horario" class="w-100" size="small" placeholder="Horario">
                            <el-option
                            v-for="item in horarios"
                            :key="item.hora"
                            :label="item.horario"
                            :value="item.hora"
                            :disabled="item.disabled"
                            />
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="col">
                <div v-if="pedido.id_pedido != null" class="row d-middle text-general">
                    <div class="pr-1 d-flex align-items-end pb-2 col-auto">
                        <input v-model="model.cliente_entrega_directa" :value="1" type="radio" class="option-input radio" />
                        <p class="f-14">Enviar al Cliente</p>
                    </div>
                    <div class="pr-1 d-flex align-items-end pb-2 col-auto">
                        <input v-model="model.cliente_entrega_directa" :value="0" type="radio" class="option-input radio" />
                        <p class="f-14">Enviar al {{$config.vendedor}}</p>
                    </div>
                </div>
                <div class="row mx-0 mt-3 text-general align-items-center">
                    <div class="col-auto px-2">
                        <el-checkbox v-model="model.otra_direccion" :true-label="1" :false-label="0" class="check-red" />
                    </div>
                    <div class="col px-2 f-14">
                        Este pedido se enviara a una direccion diferente a la registrada.
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-6">
                <p class="f-500 f-17 mb-2 text-general">Comisión del {{$config.vendedor}}</p>
            </div>
            <div class="col">
                <p class="f-500 f-17 mb-2 text-general">Crédito del Pedido</p>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-3 text-general ml-3">
                <p class="f-18 f-500">{{ convertMoney(resumen.val_condicion) }} {{ pedido.sigla_moneda }}</p>
                <p class="d-midlle f-14">
                    <i class="icon-comision f-15 mr-1" />
                    Comisión
                </p>
            </div>
            <div class="col-3 text-general">
                <p class="f-13">Cobro de la comisión</p>
                <el-select v-model="model.alianza_estado" size="small" placeholder="comisión">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </div>
            <div class="col">
                <div class="row mx-0 d-middle">
                    <p class="f-14 text-general">{{ pedido.pago_estado === 1 ? 'No tiene crédito otorgado' : 'Este pedido tiene crédito otorgado' }}</p>
                    <div v-if="pedido.pago_estado === 1 && $can('botones_abastecimiento_otorgar_credito')">
                        <div type="button" class="br-10 bg-general text-white p-2 ml-3" @click="openCredito">
                            Otorgar Crédito
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import moment from 'moment'
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    data(){
        return{
            options: [{
                value: 1,
                label: 'No Aplica'
            }, {
                value: 2,
                label: 'Cobrada'
            }, {
                value: 3,
                label: 'Por Pagar'
            }, {
                value: 4,
                label: 'Pendiente Definir'
            }],
            value: '',
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
            horarios:[],
            model:{
                entrega_fecha:'',
                entrega_horario:'',
                cliente_entrega_directa:0,
                otra_direccion:0,
                alianza_estado: 4
            },
            hora: ''
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido:'pedidos/pedidos_admin/pedido',
            resumen:'pedidos/pedidos_admin/resumen'
        }),
        btns(){
            let btns = [
                {
                    texto: 'Cerrar',
                    color: 'btn mx-2 bg-whitesmoke br-10 border text-dark',
                    accion: 'cancelar'
                },
                {
                    texto:'Finalizar',
                    color: 'btn mx-2 bg-general text-white br-10 border-0',
                    accion:'finalizar'
                },

            ]
            return btns
        }
    },
    methods: {
        toggle(){
            this.model.entrega_fecha = this.pedido.entrega_fecha
            this.model.entrega_horario = this.pedido.entrega_horario
            this.model.cliente_entrega_directa = this.pedido.cliente_entrega_directa
            this.model.otra_direccion = this.pedido.otra_direccion
            this.set_franja(this.model.entrega_fecha)
            this.$refs.modalFinalizarPedido.toggle()
        },
        accion_modal(res){
            switch (res){
            case 'cancelar':
                this.$refs.modalFinalizarPedido.toggle()
                break;
            case 'finalizar':
                this.finalizar_pedido()
                break;
            default:
            }
        },
        async openCredito(){
            this.model.finalizar = false
            const {data} = await Pedidos.finalizar_pedido(this.id_pedido,this.model) // guardamos la informacion basica antes de abrir el modal de credito
            this.$store.commit('pedidos/pedidos_admin/set_pedido', data.pedido)
            this.$refs.modalFinalizarPedido.toggle()
            let that = this
            setTimeout(function(){
                that.$emit('credito')
            }, 500);

        },
        async finalizar_pedido(){
            try {

                if(this.model.entrega_horario === ''){
                    this.notificacion('Advertencia','Por favor seleccione un horario','warning')
                    return
                }
                if(this.id_pedido === null || this.id_pedido === ''){
                    this.notificacion('Advertencia','Por favor seleccione un pedido','warning')
                    return
                }
                this.model.finalizar = true
                const {data} = await Pedidos.finalizar_pedido(this.id_pedido,this.model)

                this.$emit('accion-finalizar')
                this.$refs.modalFinalizarPedido.toggle()
                this.notificacion('Pedido Finalizado','','success')

            } catch (e){
                this.error_catch(e)
            }
        },
        async set_franja(fecha){
            try {
                let id_cedis = this.pedido.id_cedis
                const vistaAdmin = true
                const {data} = await Pedidos.setFranjasHorarios(id_cedis,fecha,vistaAdmin)
                // data.horarios.map(h=>{
                //     h.disabled = false
                // })
                this.horarios = data?.data

                if (this.hora) this.model.entrega_horario = this.hora
                if (this.model.entrega_horario != null && this.model.entrega_horario != ''){
                    let filtered = this.horarios.filter(o=>o.hora === this.model.entrega_horario)
                    
                    if (filtered.length < 1){
                        let horaMostrar = moment(this.model.entrega_horario,'H').format('hh:mm A')
                        let fechaHoy = moment().format('YYYY-MM-DD')
                        let obj = {
                            'hora':  this.model.entrega_horario,
                            'horario': horaMostrar+' - '+horaMostrar
                        }
                        if (this.model.entrega_fecha < fechaHoy){
                            this.horarios.push(obj)                            
                        }else{
                            this.hora = this.model.entrega_horario
                            this.model.entrega_horario = ''
                        }
                    }
                }
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="css" scoped>
</style>
